import React from 'react'
import {StaticQuery, graphql, Link} from 'gatsby'
import {Col, Container, Nav, NavItem, Row} from 'reactstrap'

import _ from 'lodash';
// import logo from '../assets/images/logo.png'

const Footer = class extends React.Component {
  render() {
    const data = this.props.data
    const {allCategoriesYaml: yaml} = data

    const lang = this.props.lang

    const categories = {}
    yaml.edges.map(edge => edge.node.data.map((category) => {
      if (category.lang === lang) {
        categories[category.category] = category
      }
    }))

    return (
        <footer className="bg-dark text-white py-5">
          <Container>
            <Row>
              <Col xs={12} sm={4}>
                <Nav vertical>
                  <NavItem>
                    <Link className='nav-link' to="/">
                      <i className='fa fa-home'> </i>{' '}ホーム
                    </Link>
                  </NavItem>
                  {_.map(categories, ({category, name, icon}, key) => {
                    return (<NavItem key={key}>
                      <Link className='nav-link' to={"/category/" + category}>
                        <i className={icon}> </i>{' '}{name}
                      </Link>
                    </NavItem>)
                  })}
                </Nav>
              </Col>
              <Col xs={12} sm={4}>
                <Nav vertical>
                  <NavItem>
                    <Link className='nav-link' to="/update">
                      お知らせ
                    </Link>
                  </NavItem>
                  <NavItem>
                    <a className='nav-link' href="https://anbai.app" target="_blank"
                       rel="noopener noreferrer">
                      ANBAIウェブサイト
                    </a>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
          </Container>
        </footer>
    )
  }
}

export default (props) => (
    <StaticQuery
        query={graphql`
query FooterQuery {
  allCategoriesYaml {
    edges {
      node {
        data {
          category
          name
          icon
          lang
        }
      }
    }
  }
}
`} render={(data) => <Footer data={data} {...props} />} />
)
