import React from 'react'
import Helmet from 'react-helmet'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import '../assets/scss/style.scss'
import useSiteMetadata from './SiteMetadata'

const TemplateWrapper = ({ breadcrumbs, lang, children }) => {

  const { title, description } = useSiteMetadata()

  return (
    <div>
      <Helmet>
        <html lang={lang || 'ja'} id={'html-' + (lang || 'ja')} />

        <meta charSet="utf-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
        <meta name="theme-color" content="#000000"/>
        {/*<link rel="manifest" href="/img/manifest.json"/>*/}
        <link rel="shortcut icon" href="/img/favicon.ico"/>
        <link rel="apple-touch-icon" sizes="57x57" href="/img/apple-icon-57x57.png"/>
        <link rel="apple-touch-icon" sizes="60x60" href="/img/apple-icon-60x60.png"/>
        <link rel="apple-touch-icon" sizes="72x72" href="/img/apple-icon-72x72.png"/>
        <link rel="apple-touch-icon" sizes="76x76" href="/img/apple-icon-76x76.png"/>
        <link rel="apple-touch-icon" sizes="114x114" href="/img/apple-icon-114x114.png"/>
        <link rel="apple-touch-icon" sizes="120x120" href="/img/apple-icon-120x120.png"/>
        <link rel="apple-touch-icon" sizes="144x144" href="/img/apple-icon-144x144.png"/>
        <link rel="apple-touch-icon" sizes="152x152" href="/img/apple-icon-152x152.png"/>
        <link rel="apple-touch-icon" sizes="180x180" href="/img/apple-icon-180x180.png"/>
        <link rel="icon" type="image/png" sizes="192x192" href="/img/android-icon-192x192.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="/img/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="96x96" href="/img/favicon-96x96.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="/img/favicon-16x16.png"/>
        <meta name="msapplication-TileColor" content="#ffffff"/>
        <meta name="msapplication-TileImage" content="/img/ms-icon-144x144.png"/>
        <meta name="theme-color" content="#ffffff"/>

        <title>{title}</title>
        <meta name="description" content={description} />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content="/img/og-image.jpg" />
      </Helmet>
      <div id="main-wrapper" className="is-app" dir="ltr" data-theme="light" data-layout="horizontal" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="boxed">
        <Header />
        <Sidebar lang={lang} breadcrumbs={breadcrumbs} />
        <div className="page-wrapper d-block">
          <div className="page-content container-fluid">
            {children}
          </div>
        </div>
        <Footer lang={lang} />
      </div>
    </div>
  )
}

export default TemplateWrapper
