import React from 'react'
// import { Link } from 'gatsby'
// import github from '../img/github-icon.svg'
import logo from '../assets/images/logo.png'
import logoText from '../assets/images/logo-text.png'

const Header = class extends React.Component {
  constructor(props) {
    super(props)
    // this.toggleMenu = this.toggleMenu.bind(this);
    this.showMobilemenu = this.showMobilemenu.bind(this);
  }
  // toggleMenu() {
  //   document.getElementById('search').classList.toggle('show-search');
  // }
  showMobilemenu() {
    document.getElementById('main-wrapper').classList.toggle('show-sidebar');
  }

  render() {
    return (
      <header className="topbar navbarbg" data-navbarbg="skin6">
        <nav className="top-navbar navbar-light navbar navbar-expand-md-">
          <div className="navbar-header" id="logobg" data-logobg="transparent">
            {/*<button className="nav-toggler d-block d-md-none text-primary" onClick={this.showMobilemenu}>*/}
            {/*  <i className="ti-menu ti-close" style={{fontSize: "20px"}}>*/}
            {/*  </i>*/}
            {/*</button>*/}
            <a href="/" className="navbar-brand">
              <b className="logo-icon">
                <img src={logo} alt="homepage" className="dark-logo" />
                <img src={logo} alt="homepage" className="light-logo" />
              </b>
              <span className="logo-text">
                <img src={logoText} alt="homepage" className="dark-logo" />
                <img src={logoText} className="light-logo" alt="homepage" />
              </span>
            </a>
            <div>&nbsp;</div>
            {/*<button className="topbartoggler d-block d-lg-none text-primary" onClick={this.showMobilemenu}>*/}
            {/*  <i className="ti-more" style={{fontSize: "20px"}}></i>*/}
            {/*</button>*/}
          </div>
          {/*
          <div data-navbarbg="skin6" className="navbarbg collapse navbar-collapse">
            <ul className="float-left navbar-nav">
            </ul>
            <ul className="ml-auto float-right navbar-nav">
              <li className="dropdown nav-item">
                <a aria-haspopup="true" href="#" className="pro-pic dropdown-toggle nav-link" aria-expanded="false">
                  <i className="fa fa-user">
                  </i>
                </a>
                <div tabIndex="-1" role="menu" aria-hidden="true" className="user-dd dropdown-menu dropdown-menu-right">
                  <div className="d-flex no-block align-items-center p-3 mb-2 border-bottom">
                    <div className="">
                      <i className="fa fa-user mr-1 ml-1">
                      </i>
                    </div>
                    <div className="mx-3">
                      <div className="row font-12">
                        <div className="col-12 text-nowrap">DUMSCO <span>ダッシュボード</span>
                        </div>
                        <div className="col-6 text-nowrap">
                          <span>チームID</span>
                        </div>
                        <div className="col-6 text-nowrap">dumsco</div>
                        <div className="col-6 text-nowrap">
                          <span>ユーザーID</span>
                        </div>
                        <div className="col-6 text-nowrap">dumscoadmin</div>
                      </div>
                    </div>
                  </div>
                  <a href="/team/authentication/logout" tabIndex="0" className="dropdown-item">
                    <i className="fa fa-power-off mr-1 ml-1">
                    </i> <span>ログアウト</span>
                  </a>
                </div>
              </li>
            </ul>
          </div>
          */}
        </nav>
      </header>
    )
  }
}

export default Header
