import React from 'react'
import { Link } from 'gatsby'
import { StaticQuery, graphql } from 'gatsby'
// import github from '../img/github-icon.svg'
// import logo from '../img/logo.svg'

const Sidebar = class extends React.Component {
  render() {
    const breadcrumbs = this.props.breadcrumbs
    // const categories = data.allCategoriesYaml.edges[0].node.data

    const title = this.props.data.site.siteMetadata.title
    return (
      <aside className="left-sidebar" id="sidebarbg" data-sidebarbg="skin3">
        <div className="scroll-sidebar">
          <div className="sidebar-nav">
            <ul id="breadcrumbs" className="nav">
              <li className="breadcrumbsItem">
                <Link to="/" className="breadcrumbsItemLink">
                  <i className="fas fa-bookmark"> </i>
                  {' '}
                  <span>{title}</span>
                </Link>
              </li>
              {breadcrumbs && breadcrumbs.map(({name, link}, i) => (
                <li key={i} className={"breadcrumbsItem" + (i > 0 ? ' d-none d-sm-block' : '')}>
                  <Link to={(link.startsWith('/') ? link : '/' + link)} className="breadcrumbsItemLink">
                    <span>{' '}&gt;{' '}</span>
                    <span>{name}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </aside>

    )
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
query SidebarQuery {
  site {
    siteMetadata {
      title
    }
  }
}
    `}
    render={(data) => <Sidebar data={data} {...props} />}
  />
)
